.informationWrapper {
  position: fixed;
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 325px 180px;
  grid-template-areas:
    "profile whiteBackground"
    "tags whiteBackground"
    "digitalClock whiteBackground";
  min-height: 100vh;
}

.whiteBackground {
  grid-area: whiteBackground;
  background: white;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.resumeWrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-left: 505px;
  padding: 70px 0px 0px;
  overflow-x: hidden;
  overflow-y: hidden;

  .footer {
    margin-top: auto;
    //color: $primary;
    font-weight: 600;
  }
}

@media only screen and (max-width: 1000px) {
  .informationWrapper {
    position: static;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: fit-content;
  }

  .whiteBackground {
    position: absolute;
    top: 32px;
    right: 24px;
    background: transparent;
  }

  .resumeWrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    margin-left: 0;
    padding: 0;
  }

  div.tabletNotShown {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .informationWrapper {
    position: static;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: fit-content;
  }

  .whiteBackground {
    position: static;
    width: 100%;
    background: transparent;
  }

  .resumeWrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-left: 0;
    padding: 0;
  }

  div.tabletNotShown {
    display: none;
  }
}
