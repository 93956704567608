.tags {
  grid-area: tags;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    color: $text;
    text-decoration: none;
  }
}

svg.eachTag {
  padding: 8px;
  width: 40px;
  height: 40px;
  cursor: pointer;

  &-github {
    padding: 8px {
      left: 16px;
      right: 16px;
    }
    width: 31px;
    height: 31px;
  }
  &-gmail {
    width: 50px;
    height: 40px;
  }
}

@media only screen and (max-width: 1000px) {
  .tags {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding-left: 48px;
    margin-top: 0;
  }
}
