.profile {
  grid-area: profile;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

h2.name {
  padding: 24px;
  color: $primary;
}

h4.title {
  margin-bottom: 8px;
}

h5.email {
  margin-top: 8px;
  margin-bottom: 8px;
  svg.emailIcon {
    display: inline-block;
    vertical-align: sub;
  }
}

@media only screen and (max-width: 1000px) {
  .profile {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: fit-content;
    margin-top: 72px;
    padding-left: 48px;
    box-sizing: border-box;
    background: white;
  }

  h2.name {
    padding: 12px;
  }

  h4.title,
  h5.email {
    padding-left: 12px;
  }

  h5.email {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .profile {
    margin-top: 24px;
    margin-bottom: 24px;
    padding-left: 24px;
    background: $light;
  }

  h4.title {
    margin-top: 16px;
  }
}
