.contactWrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 80%;
  margin-bottom: 40px;

  p {
    width: 100%;
  }
}

.contact-title {
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid $gray-400;
  color: $primary;
  //text-align: center;
}

.btnWrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 100%;
}

.contactMeBtn {
  min-width: 132px;
  padding: 12px {
    top: 4px;
    bottom: 4px;
  }
  margin: 8px;
  border-radius: 4px;
  @include a-font;
  font-size: 1.25rem;
  background: $primary;
  color: white;

  .contactMeALink {
    text-decoration: none;
    color: white;
  }

  .contactMeIcon {
    width: 26px;
    height: 26px;
    vertical-align: sub;
  }
}

.myResumeBtn {
  min-width: 132px;
  padding: 12px {
    top: 4px;
    bottom: 4px;
  }
  margin: 8px;
  border-radius: 4px;
  @include a-font;
  font-size: 1.25rem;
  background: $primary;
  color: white;

  .myResumeALink {
    text-decoration: none;
    color: white;
  }

  .myResumeIcon {
    width: 22px;
    height: 22px;
    vertical-align: sub;
  }
}

@media only screen and (max-width: 600px) {
  .contactWrapper {
    width: 90%;
  }
}

@media only screen and (max-width: 340px) {
  .btnWrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .contactMeBtn {
    margin: 6px;
  }

  .myResumeBtn {
    margin: 6px;
  }
}
