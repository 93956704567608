.projectsWrapper {
  display: flex;
  flex-flow: column nowrap;
  width: 80%;
  margin-bottom: 20px;
}

.projectsTitle {
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 30px;
  border-bottom: 1px solid $gray-400;
  color: $primary;
}

.eachProject {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin-bottom: 40px;
}

.logoWrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background: white;

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    transform: rotate(45deg);
    left: 75px;
    top: 20px;
    z-index: -1;
  }
}

.projectLogo {
  width: 50px;
  height: 50px;
  margin: 4px;
  border-radius: 50%;
}

.projectDetailWrapper {
  margin-left: 80px;
  padding: 20px;
  padding-bottom: 60px;
  border-radius: 6px;
  background: white;
}

.projectTitle {
  width: 100%;
}

.projectDetail {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
}

.bizmsmPicWrapper {
  width: 50%;
  padding: 8px;
  box-sizing: border-box;
}

.bizmsmPic {
  width: 100%;
  height: auto;
}

.dashboardPicWrapper {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.dashboardPic {
  display: block;
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 600px) {
  .projectsWrapper {
    width: 90%;
  }

  .eachProject {
    align-items: center;
  }

  .logoWrapper {
    position: relative;
    width: 68px;
    height: 68px;
    margin-bottom: 20px;
    border-radius: 50%;
    background: white;

    &::after {
      content: "";
      width: 15px;
      height: 15px;
      left: 28px;
      top: 80px;
    }
  }

  .projectLogo {
    width: 60px;
    height: 60px;
  }

  .projectDetailWrapper {
    width: 90%;
    margin-left: 0px;
  }
}
