.aboutMeWrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 80%;
  margin-bottom: 20px;
}

.aboutMe-title {
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid $gray-400;
  color: $primary;
  //text-align: center;
}

div.onlyMobileAndTabletShown,
.onlyMobileAndTabletShown {
  display: none;
}

@media only screen and (max-width: 1000px) {
  div.onlyMobileAndTabletShown {
    display: flex;
    justify-content: center;
    padding: 0;
  }

  .onlyMobileAndTabletShown {
    display: inline;
  }
}

@media only screen and (max-width: 600px) {
  .aboutMeWrapper {
    width: 90%;
  }
}
