.digitalClock {
  grid-area: digitalClock;
  align-self: flex-end;
  justify-self: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-bottom: 50px;
}

.seconds {
  color: $primary;
}

q.slogan {
  margin-top: 8px;
  font-weight: 600;
}

@media only screen and (max-width: 1000px) {
  .digitalClock {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
    margin-top: 50px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .digitalClock {
    margin-top: 40px;
    margin-bottom: 50px;
  }
}
