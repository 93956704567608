.selectionsWrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 80px 0 20px;
}
.eachSelection {
  padding: 8px;
  font-size: 1.25rem;
  font-weight: 700;
  color: $text;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  &-clicked {
    color: $primary;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 1000px) {
  .selectionsWrapper {
    display: none;
  }
}
