.avatarWrapper {
  overflow: hidden;
  width: 272px;
  height: 272px;
  border-radius: 50%;
  background: white;
}
.avatar {
  width: 240px;
  height: 240px;
  margin: 16px;
  border-radius: 50%;
}
