.experienceWrapper {
  display: flex;
  flex-flow: column nowrap;
  width: 80%;
  margin-bottom: 20px;
}

.experienceTitle {
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid $gray-400;
  color: $primary;
}

.eachExperience {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin-bottom: 8px;
}

.jobDuration {
  position: absolute;
  right: 0;
  top: 6px;
  color: $primary;
  font-size: 1rem;
  font-weight: 400;
}

.jobTitle {
  width: 70%;
}

.jobLocation {
  padding-top: 8px;
  width: 100%;
}

svg.locationIcon {
  width: 18px;
  height: 18px;
  vertical-align: text-top;
  color: $primary;
}

.jobDetail {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 12px;
}

@media only screen and (max-width: 1000px) {
  .jobDetail {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .experienceWrapper {
    width: 90%;
  }

  .jobTitle {
    width: 100%;
  }

  .jobDuration {
    position: static;
    display: inline;
    color: $gray-600;
    padding-top: 8px;
    font-weight: 400;
  }
}
