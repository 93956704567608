.skillsWrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 80%;
  margin-bottom: 40px;
}

.skills-title {
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid $gray-400;
  color: $primary;
  //text-align: center;
}

.skillsProgress {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.eachSkill {
  position: relative;
  width: 50%;
  padding: 8px;
  box-sizing: border-box;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .skillsWrapper {
    width: 90%;
  }

  .eachSkill {
    position: relative;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    font-weight: 600;
  }
}
