// Global styling for this template
html {
  scroll-behavior: smooth;
}

body {
  @include body-font;
  color: $text;
  background: $light;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include heading-font;
  margin: 0;
  padding: 0;
  font-weight: 700;
  //text-transform: uppercase;
  color: $gray-800;
}

h1 {
  font-size: 6rem;
  line-height: 5.5rem;
  text-transform: uppercase;
}

h2 {
  font-size: 3.5rem;
  line-height: 1.2;
  text-transform: uppercase;
}

h3 {
  font-size: 2rem;
  line-height: 1.2;
}

h4 {
  font-size: 1.5rem;
  line-height: 1.2;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 1.125rem;
  margin-top: 16px;
  margin-bottom: 16px;
}

.animatedSection {
  opacity: 0;
}
